import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "katex/dist/katex.min.css";
import styled from "styled-components";
import { MarkdownToHtml } from "../components/MarkdownToHtml";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fetchChapterDetail } from "src/hooks/useLesson";
import { fetchLessonDetail } from "../hooks/useLesson";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSidebar = styled.div`
  width: 300px;
  min-width: 250px;
  padding: 20px;
  border-left: 1px solid #ddd;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;

  h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
`;

interface SidebarListProps {
  selected: boolean;
}

const SidebarList = styled.li<SidebarListProps>`
  cursor: pointer;
  list-style:none;
  line-height: 1.8;
  font-size: 12px;
  color: ${({ selected }) => (selected ? "rgba(40, 142, 175, 0.9)" : "rgba(0, 0, 0, 0.5)")};

  &:hover {
    color: rgba(40, 142, 175, 0.9);
    font-weight: bold;
  }

  &:not(:last-child) {
    &::after{
      content: '↓';
      display: block;
      font-size: 24px;
      color: rgba(40, 142, 175, 0.9);
      padding: 4px 0;
    }
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const MovieContainer = styled.div`
  width: 100%;
  padding: 20px 45px;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;

  .iframe-container {
    display: flex;
    width: 100%;
  }

  iframe {
    width: 100%;
    aspect-ratio: 1/0.5625;
  }

  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;

    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(40, 142, 175, 0.9);
      margin-right: 8px;
    }
  }
`;

const HeadingContainer = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 20px 45px;

  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 0;

    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(40, 142, 175, 0.9);
      margin-right: 8px;
    }
  }
`;

const BackButton = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: rgba(1, 1, 1, 0.6);
  }
`;

interface ChapterDetailState {
    id: string;
    content: string;
    number: string;
    title: string;
    movie_path: string;
}

interface Chapter {
  id: string;
  number: number;
  title: string;
}

interface SidebarProps {
  chapters: Chapter[];
  selectedChapterId?: string;
  onSelectChapter: (chapterId: string) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ chapters, selectedChapterId, onSelectChapter }) => {
  return (
    <>
      <h2>Chapters</h2>
      <div className="sidebar">
        {
          chapters.map((chapter) => (
            <SidebarList 
              key={chapter.id} 
              selected={selectedChapterId === chapter.id}
              onClick={() => onSelectChapter(chapter.id)}
            >
              Chapter {chapter.number}
              <br />
              {chapter.title}
            </SidebarList>
          ))
        }
      </div>
    </>
  );
};


export const ChapterDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lessonId } = useParams<{ lessonId: string }>();
  const { chapterId } = useParams<{ chapterId: string }>();
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [chapterDetail, setChapterDetail] = useState<ChapterDetailState | null>(null);

  useEffect(() => {
    // ページ遷移後にトップにスクロール
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    // チャプタの内容を取得
    const fetchData = async () => {
      if (chapterId) {
        const data = await fetchChapterDetail(chapterId);
        setChapterDetail(data);
      }
    };
    fetchData();
  }, [chapterId]);

  useEffect(() => {
    // サイドバーで表示するチャプターの一覧を取得
    const fetchData = async () => {
      if (lessonId) {
        const data = await fetchLessonDetail(lessonId);
        setChapters(data.chapters);
      }
    };
    fetchData();
  }, [lessonId]);

  const handleSelectChapter = (selectedChapterId: string) => {
    // 選択されたチャプタのページに遷移
    navigate(`/lesson/${lessonId}/${selectedChapterId}`);
  };


  return (
    <>
      <Header />

      <Wrapper>
        <ContentArea>
          {chapterDetail?.movie_path ? 
            (<MovieContainer>
              <h2>まずは動画で流れをつかむ</h2>
                <iframe
                src={chapterDetail?.movie_path}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                ></iframe>
              </MovieContainer>
            ) : null
          }
          
          <HeadingContainer>
            <BackButton onClick={() => navigate(-1)}>
              <AiOutlineArrowLeft size="20px" />
              戻る
            </BackButton>
            {chapterDetail?.movie_path ? 
              <h2>テキストを見ながらじっくり学ぶ</h2>
              : null
            }
          </HeadingContainer>

          <MarkdownToHtml markdownString={chapterDetail?.content ?? ""} />
        </ContentArea>

        <StyledSidebar>
          <Sidebar 
            chapters={chapters} 
            selectedChapterId={chapterId}
            onSelectChapter={handleSelectChapter} 
          />
        </StyledSidebar>
      </Wrapper>
      
      <Footer />
    </>
  );
};
