import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.footer`
  width: 100%;
  background-color: rgba(211, 211, 211, 0.6);
  padding: 30px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FooterLogo = styled.div`
  width: 230px;
  margin-top: 20px;
  margin-left: 50px;
  text-align: center;
  img {
    width: 120px;
  }
  p {
    margin-top: 24px;
    font-size: 12px;
    line-height: 1.4;
  }
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const ForUse = styled.div`
  width: 230px;
  margin-top: 20px;
  margin-left: 100px;
  text-align: left;
  h3 {
    font-weight: bold;
    font-size: 16px;
  }
  p {
    text-align: left;
    margin-top: 12px;
  }
  a {
    text-decoration: none;
    color: black;
    
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`;

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <ContentWrapper>
        <FooterLogo>
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/footer_logo.png`}
              alt={"Labcode Study"}
            />
            <p>
              動画とテキストのハイブリッドで学べる学習サービスです。研究で使えるプログラミングはLabCodeから！
            </p>
          </Link>
        </FooterLogo>
        <ForUse>
          <h3>ご利用にあたって</h3>
          <p>・<a href="/terms">利用規約</a></p>
          <p>・<a href="/privacy">プライバシーポリシー</a></p>
        </ForUse>
      </ContentWrapper>
      <Copyright>
        <span>&#169;</span> 2024 labcode study All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
};
