// レッスン一覧を取得するカスタムフック

// const BASE_DOMAIN = "http://localhost:8000";
const BASE_DOMAIN = "https://adroit-lantern-424309-d6.dt.r.appspot.com";

export const fetchLessons = async () => {
  const response = await fetch(BASE_DOMAIN + "/api/lessons");
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data.lessons;
};

export const fetchLessonDetail = async (lessonId: string) => {
  const response = await fetch(BASE_DOMAIN + `/api/lesson/${lessonId}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

export const fetchChapterDetail = async (chapterId: string) => {
  const response = await fetch(BASE_DOMAIN + `/api/chapter/${chapterId}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};
