import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: rgba(40, 142, 175, 0.2);
  padding: 20px;
`;

const HeadingMessage = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 36px;
  margin-bottom: 36px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const OverviewImage = styled.img`
  max-width: 75%;
  max-height: 75%;
  height: auto;
`;

export const ServiceOverview = () => {
  return (
    <Container>
      <HeadingMessage>
        研究に直結、すぐに使えるスキル
      </HeadingMessage>
      <Message>
        すぐに研究に使える内容だけを厳選し、わかりやすく解説しています
      </Message>
      <ImageContainer>
        <OverviewImage
          src={`${process.env.PUBLIC_URL}/images/overview.png`}
          alt={"Overview Image"}
        />
      </ImageContainer>
    </Container>
  );
};
