import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "katex/dist/katex.min.css";
import styled from "styled-components";
import { MarkdownToHtml } from "../components/MarkdownToHtml";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetchLessonDetail } from "../hooks/useLesson";
import { AiOutlineArrowLeft } from "react-icons/ai";



const LessonEyeCatch = styled.div`
  background-color: rgba(40, 142, 175, 0.2);
  display: flex;
  align-items: center;
  height: 400px;
  width: 100%;
`;

const LessonContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LessonLogo = styled.img`
  border: solid 5px black;
  border-radius: 50%;
  margin: 0 0 0 auto;
  height: 150px;
  width: 150px;
`;

const LessonTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  height: 150px;
  line-height: 1.4;
  margin: 0 auto 0 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: pre-wrap;
`;

const BackButtonContainer = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 30px 20px;
`;

const BackButton = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: rgba(1, 1, 1, 0.6);
  }
`;

const Chapters = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 10px 45px 45px;
  h2 {
    font-weight: bold;
    font-size: 28px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgb(216, 222, 228);
  }
`;

const ChapterContainer = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  margin-top: 20px;
  margin-bottom: 20px;

  h3 {
    font-weight: bold;
    font-size: 18px;
    color: rgb(40, 142, 175);
  }
  p {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

interface Chapter {
  id: string;
  number: number;
  title: string;
}

interface LessonDetailState {
  id: string;
  language: string;
  logo_image_path: string;
  title: string;
  subtitle: string;
  summary: string;
  chapters: Chapter[];
}


export const LessonDetail: React.FC = () => {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [lessonDetail, setLessonDetail] = useState<LessonDetailState | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (lessonId) {
        const data = await fetchLessonDetail(lessonId);
        setLessonDetail(data);
      }
    };
    fetchData();
  }, [lessonId]);

  return (
    <>
      <Header />
      <LessonEyeCatch>
        <LessonContent>
          <LessonLogo
            src={lessonDetail?.logo_image_path}
          />
          <LessonTitle>
            {lessonDetail?.title}
          </LessonTitle>
        </LessonContent>
      </LessonEyeCatch>
      
      <BackButtonContainer>
        <BackButton onClick={() => navigate(-1)}>
          <AiOutlineArrowLeft size="20px" />
          戻る
        </BackButton>
      </BackButtonContainer>

      <MarkdownToHtml markdownString={lessonDetail?.summary ?? ""} />
      <Chapters>
        <h2>Chapters</h2>
        {lessonDetail?.chapters.map((chapter, index) => (
            <ChapterContainer key={index}>
              <h3>Chapter {chapter.number}</h3>
              <Link to={`/lesson/${lessonId}/${chapter.id}`}>
                <p>{chapter.title}</p>
              </Link>
            </ChapterContainer>
        ))}
      </Chapters>
      <Footer />
    </>
  );
};
