import { Routes, Route } from "react-router-dom";
import { Top } from "./pages/Top";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import React from "react";
import { LessonDetail } from "./pages/LessonDetail";
import { ChapterDetail } from "./pages/ChapterDetail";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsOfService } from "./pages/TermsOfService";

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
`;

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path={`/`} element={<Top />} />
        <Route path={`/lesson/:lessonId`} element={<LessonDetail />} />
        <Route path={`/lesson/:lessonId/:chapterId`} element={<ChapterDetail />} />
        <Route path={`/terms`} element={<TermsOfService />} />
        <Route path={`/privacy`} element={<PrivacyPolicy />} />
        <Route path={`/*`} element={<div>Not Found</div>} />
      </Routes>
    </>
  );
};

export default App;
