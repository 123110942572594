import styled from "styled-components";
import React from "react";

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
`;

const HeadingMessage = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 36px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 36px 0;
  width: 100%;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  img {
    width: 100px;
  }
  h3 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 30px;
  }
  p {
    font-size: 16px;
    margin-top: 30px;
  }
`;

const contents = [
  {
    key: "movie",
    title: "動画でさくっと概要を掴める",
    text: "コードの実行方法などの動きの情報は動画で学べます",
    imagePath: `${process.env.PUBLIC_URL}/images/movie_icon.png`,
  },
  {
    key: "text",
    title: "テキストでじっくり学べる",
    text: "じっくり理解する何度も復習するにはテキストが最適",
    imagePath: `${process.env.PUBLIC_URL}/images/book_icon.png`,
  },
];

export const PointOfService = () => {
  return (
    <Container>
      <HeadingMessage>
        動画とテキストの
        <br />
        ハイブリッド学習
      </HeadingMessage>
      <ContentWrapper>
        {contents.map((content) => {
          return (
            <Content key={content.key}>
              <img src={content.imagePath} alt={content.title} />
              <h3>{content.title}</h3>
              <p>{content.text}</p>
            </Content>
          );
        })}
      </ContentWrapper>
    </Container>
  );
};
