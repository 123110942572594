import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  background-color: rgba(40, 142, 175, 0.2);
  padding: 20px;
`;

const HeadingMessage = styled.h2`
  font-weight: bold;
  font-size: 32px;
  line-height: 1.6;
  margin-top: 36px;
  text-align: center;
`;

const LessonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 100%;
`;

const Lesson = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  img {
    width: 200px;
  }
  p {
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 250px;
    line-height: 1.6;
  }
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    opacity: 0.8;
  }
`;

interface LessonProps {
  id: number;
  cover_image_path: string;
  title: string;
}

interface LessonsProps {
  lessons: LessonProps[];
}

export const Lessons: React.FC<LessonsProps> = ({lessons}) => {
  return (
    <Container>
      <HeadingMessage>レッスン一覧</HeadingMessage>
      <LessonWrapper>
        {lessons.map((lesson) => (
          <Lesson key={lesson.id}>
            <Link to={`/lesson/${lesson.id}`}>
              <img src={lesson.cover_image_path} alt={`Lesson ${lesson.id}`} />
              <p>{lesson.title.replace(/\n/g, "")}</p>
            </Link>
          </Lesson>
        ))}
      </LessonWrapper>
    </Container>
  );
};
