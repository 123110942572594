import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import styled from "styled-components";
import { ServiceOverview } from "../components/ServiceOverview";
import { PointOfService } from "../components/PointOfService";
import { Lessons } from "../components/Lessons";
import { fetchLessons } from "../hooks/useLesson";

const EyeCatch = styled.img`
  width: 100%;
  vertical-align:top;
`;

export const Top: React.FC = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const data = fetchLessons(); 
    data.then((res) => {
      setLessons(res);
    });
  }, []);

  return (
    <>
      <Header />
      <EyeCatch
        src={`${process.env.PUBLIC_URL}/images/eye_catch.png`}
        alt={"EyeCatch Image"}
      />
      <ServiceOverview />
      <PointOfService />
      {lessons ? <Lessons lessons={lessons} /> : <div>ただいまレッスンの準備中です</div>}
      <Footer />
    </>
  );
};
