import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import styled from "styled-components";
import "github-markdown-css";

const MarkdownToHtmlContainer = styled.div`
  width: 100%;
  padding: 20px;

  &.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    margin: 0 auto;
    padding: 45px;
    ul {
      list-style-type: disc;
    }
    p {
      line-height: 2;
    }
  }

  @media (max-width: 767px) {
    &.markdown-body {
      padding: 15px;
    }
  }
`;

interface Props {
  markdownString: string;
}

export const MarkdownToHtml: React.FC<Props> = ({ markdownString }) => {
  return (
    <MarkdownToHtmlContainer className="markdown-body">
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
      >
        {markdownString}
      </ReactMarkdown>
    </MarkdownToHtmlContainer>
  );
};
