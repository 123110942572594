import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.header`
  background-color: #fff;
  border-bottom: solid 1px #ddd;
  display: flex;
  max-height: 72px;
  padding: 20px 24px;
  width: 100%;
`;

const HeaderLogo = styled.div`
  img {
    height: 32px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const LoginButton = styled.div`
  background-color: #288eaf;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  line-height: 36px;
  margin-left: auto;
  width: 120px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
`;

export const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <Link to="/" style={{ textDecoration: "none" }}>
        <HeaderLogo>
          <img
            src={`${process.env.PUBLIC_URL}/images/header_logo.png`}
            alt={"LabCode Study"}
          />
        </HeaderLogo>
      </Link>
      {/*  TODO: ログイン処理の実装は後日する */}
      <LoginButton>ログイン</LoginButton>
    </HeaderContainer>
  );
};
